import { Component } from "react"

class Carousel extends Component {
  componentDidMount() {
    window.addEventListener("keydown", ({ isComposing, keyCode }) => {
      if (isComposing) return

      const rightArrowPressed = keyCode === 39
      const leftArrowPressed = keyCode === 37

      if (rightArrowPressed) this.goForward()
      else if (leftArrowPressed) this.goBack()
    })
  }

  goForward = () => {
    this.props.goForward()
  }

  goBack = () => {
    this.props.goBack()
  }

  render() {
    return this.props.children
  }
}

export default Carousel
