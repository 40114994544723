import React from "react"
import { css } from "@emotion/core"
import Image from "gatsby-image"

const Slide = ({ fluid, alt, active }) => (
  <div className={`slide ${active ? "active" : ""}`}>
    <Image
      fluid={fluid}
      imgStyle={{ objectFit: "fill" }}
      css={css`
        width: 100%;
        max-width: 680px;
        min-height: 94vh;
        * {
          margin-top: 0;
        }
      `}
      alt={alt}
    />
  </div>
)

export default Slide
