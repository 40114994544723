import React from "react"
import { navigate } from "gatsby"
import Controls from "./controls"
import Slider from "./slider"
import Slide from "./slide"
import Carousel from "./carousel"
import "./app.css"

function App({ slides, manga, currentChapter }) {
  
  const finishedState = { lastChapterRead: currentChapter.number, loadNewChapter: true }

  const finish = () => {
    navigate(`${manga.slug}/`, { state: finishedState })
  }

  return (
    <div className="App">
      <Slider slides={slides} onChange={() => window.scrollTo({ top: 0 })}>
        {({ currentIndex, select, next, prev }) => (
          <div className="content">
            <Carousel goBack={prev} goForward={next}>
              {slides.map((slide, index) => (
                <Slide
                  alt=""
                  key={`slide_${index}`}
                  active={index === currentIndex}
                  fluid={slide.fluid}
                />
              ))}
            </Carousel>
            <Controls>
              <Controls.PrevButton onClick={prev} text="ANTERIOR" />
              <Controls.Select
                selectRef={select.ref}
                onChange={select.change}
                value={currentIndex}
                options={slides}
              />
              {currentIndex < slides.length - 1 ? (
                <Controls.NextButton onClick={next} text="SIGUIENTE" />
              ) : (
                <Controls.NextButton onClick={finish} text="FINALIZAR" />
              )}
            </Controls>
          </div>
        )}
      </Slider>
    </div>
  )
}

export default App
