import { useState, useRef } from "react"

const Slider = ({ slides, onChange, children }) => {
  const [index, setIndex] = useState(0)
  const selectEl = useRef(null)

  const next = () => {
    if (index < slides.length - 1) setIndex(index + 1)
    onChange()
  }
  const prev = () => {
    if (index > 0) setIndex(index - 1)
    onChange()
  }

  const select = {
    ref: selectEl,
    change() {
      const newIndex = +selectEl.current.value
      if (slides[newIndex]) setIndex(newIndex)
      onChange()
    },
  }

  return children({ next, prev, select, currentIndex: index })
}

export default Slider
