import React, { Component } from "react"

class Controls extends Component {
  static NextButton = ({ onClick, text }) => (
    <button onClick={onClick}>
      <span className="text">{text}</span> &rarr;
    </button>
  )

  static PrevButton = ({ onClick, text }) => (
    <button onClick={onClick}>
      &larr; <span className="text">{text}</span>
    </button>
  )

  static Select = ({ selectRef, onChange, value, options }) => (
    <select ref={selectRef} onChange={onChange} value={value}>
      {options.map((_, index) => (
        <option key={`option_${index}`} value={index}>
          {index + 1}
        </option>
      ))}
    </select>
  )

  render() {
    return <div className="controls">{this.props.children}</div>
  }
}

export default Controls
