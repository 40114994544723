import React from "react"
import { graphql } from "gatsby"
import App from "../components/app"

export const query = graphql`
  query($slug: String!, $chapterNumber: Int!) {
    strapiManga(
      slug: { eq: $slug }
      chapters: { elemMatch: { number: { eq: $chapterNumber } } }
    ) {
      title
      slug
      chapters {
        number
        pages {
          name
          localFile {
            sharp: childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      hero {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

const ChapterTemplate = (
  { data: { strapiManga: manga }, pageContext },
  rest
) => {
  const { chapterNumber } = pageContext
  const chapter = manga.chapters.find(
    chapter => chapter.number === chapterNumber
  )

  if (!chapter) return <h1>404</h1>

  const slides = chapter.pages.map(page => ({
    name: page.name,
    fluid: page.localFile.sharp.fluid,
  }))

  const sortedSlides = slides.sort((currentPage, nextPage) => {
    const currentPageNumber = Number(currentPage.name.replace(/\D/g, ""))
    const nextPageNumber = Number(nextPage.name.replace(/\D/g, ""))
  
    return  currentPageNumber - nextPageNumber
  })

  return <App slides={sortedSlides} manga={manga} currentChapter={chapter} />
}

export default ChapterTemplate
